import React from "react";
import Logo from "../assets/coastech_logo.png";

function footer() {
  return (
    <footer className="bg-blue-950">
      <div className="lg:flex justify-center mb-4">
        <div className="w-64">
          <img src={Logo} alt="" />
        </div>
      </div>
      <div className="mx-auto max-w-screen-xl px-4  sm:px-6 lg:px-8">
        <div className="mt-8 grid grid-cols-2 gap-8 lg:mt-0 lg:grid-cols-5 ">
          <div className="col-span-2 sm:col-span-1">
            <p className="font-medium text-sky-500">Services</p>

            <ul className="mt-6 space-y-4 text-sm text-white">
              <li>
                <a href="/" className=" transition hover:opacity-75">
                  Home
                </a>
              </li>

              <li>
                <a href="/about" className=" transition hover:opacity-75">
                  About
                </a>
              </li>

              <li>
                <a href="/programs" className=" transition hover:opacity-75">
                  Programs
                </a>
              </li>

              <li>
                <a href="/" className=" transition hover:opacity-75">
                  Events
                </a>
              </li>
            </ul>
          </div>

          <div className="col-span-2 sm:col-span-1">
            <p className="font-medium text-sky-500">Company</p>

            <ul className="mt-6 space-y-4 text-sm text-white">
              <li>
                <a href="/team" className=" transition hover:opacity-75">
                  Meet the Team
                </a>
              </li>

              <li>
                <a href="/downloads" className=" transition hover:opacity-75">
                  Downloads
                </a>
              </li>

              <li>
                <a href="/" className="ttransition hover:opacity-75">
                  Resources
                </a>
              </li>
            </ul>
          </div>

          <div className="col-span-2 sm:col-span-1">
            <p className="font-medium text-sky-500">Helpful Links</p>

            <ul className="mt-6 space-y-4 text-sm text-white">
              <li>
                <a href="/" className=" transition hover:opacity-75">
                  Contact
                </a>
              </li>

              <li>
                <a href="/faq" className=" transition hover:opacity-75">
                  FAQs
                </a>
              </li>

              <li>
                <a href="https://wa.me" className=" transition hover:opacity-75">
                  Join Whatsapp Group
                </a>
              </li>
            </ul>
          </div>

          <div className="col-span-2 sm:col-span-1">
            <p className="font-medium text-sky-500">Resources</p>

            <ul className="mt-6 space-y-4 text-sm text-white">
              <li>
                <a href="/faq" className=" transition hover:opacity-75">
                  Membership policy
                </a>
              </li>

              <li>
                <a href="/jobs" className=" transition hover:opacity-75">
                  Jobs
                </a>
              </li>
            </ul>
          </div>

          <div className="col-span-2 sm:col-span-1">
            <p className="font-medium text-sky-500">Downloads</p>

            <ul className="mt-6 space-y-4 text-sm text-white">
              <li>
                <a href="/calender" className=" transition hover:opacity-75">
                  Events Calendar
                </a>
              </li>

              <li>
                <a href="/" className=" transition hover:opacity-75">
                  Contacts
                </a>
              </li>
            </ul>
          </div>

          <ul className="col-span-2 flex justify-start gap-6 lg:col-span-5 lg:justify-end">
            <li>
              <a
                href="https://www.facebook.com/CoastechEmpowerment"
                rel="noreferrer"
                target="_blank"
                className="text-sky-500 transition hover:opacity-75"
              >
                <span className="sr-only">Facebook</span>

                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </li>

            {/* <li>
              <a
                href="/"
                rel="noreferrer"
                target="_blank"
                className="text-sky-500 transition hover:opacity-75"
              >
                <span className="sr-only">Instagram</span>

                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </li> */}

            <li>
              <a
                href="https://twitter.com/coastech_emp"
                rel="noreferrer"
                target="_blank"
                className="text-sky-500 transition hover:opacity-75"
              >
                <span className="sr-only">X</span>

                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0,0,256,256"
                >
                  <g fill="#339af0">
                    <g transform="scale(8.53333,8.53333)">
                      <path d="M26.37,26l-8.795,-12.822l0.015,0.012l7.93,-9.19h-2.65l-6.46,7.48l-5.13,-7.48h-6.95l8.211,11.971l-0.001,-0.001l-8.66,10.03h2.65l7.182,-8.322l5.708,8.322zM10.23,6l12.34,18h-2.1l-12.35,-18z"></path>
                    </g>
                  </g>
                </svg>
              </a>
            </li>

            <li>
              <a
                href="https://github.com/orgs/Coastech-Emp"
                rel="noreferrer"
                target="_blank"
                className="text-sky-500 transition hover:opacity-75"
              >
                <span className="sr-only">GitHub</span>

                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="https://www.linkedin.com/company/coastech-empowerment-community/"
                rel="noreferrer"
                target="_blank"
                className="text-sky-500 transition hover:opacity-75"
              >
                <span className="sr-only">Linked In</span>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  x="0px"
                  y="0px"
                  width="100"
                  height="100"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#0078d4"
                    d="M8.421,14h0.052l0,0C11.263,14,13,12,13,9.5C12.948,6.945,11.263,5,8.526,5S4,6.945,4,9.5	C4,12,5.736,14,8.421,14z M4,17h9v26H4V17z M44,26.5c0-5.247-4.253-9.5-9.5-9.5c-3.053,0-5.762,1.446-7.5,3.684V17h-9v26h9V28l0,0	c0-2.209,1.791-4,4-4s4,1.791,4,4v15h9C44,43,44,27.955,44,26.5z"
                  ></path>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="mt-8 pb-4 border-t border-gray-100 pt-8">
        <div className="sm:flex sm:justify-between">
          <p className="text-xs text-sky-500">
            &copy; 2023. CTEC All rights reserved.
          </p>

          <ul className="mt-8 flex flex-wrap justify-start gap-4 text-xs sm:mt-0 lg:justify-end text-sky-500">
            <li>
              <a href="/t&c" className=" transition hover:opacity-75">
                Terms & Conditions
              </a>
            </li>

            <li>
              <a href="/policy" className=" transition hover:opacity-75">
                Privacy Policy
              </a>
            </li>

            <li>
              <a href="/cookies" className=" transition hover:opacity-75">
                Cookies
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default footer;
