import React from "react";
// import "./icon.css";

function Sleeve() {
  return (
    <div className=" flex justify-center items-center bg-transparent">
      <div className="max-w-5xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {/* Column 1 */}
        <div className="text-center p-4 bg-black bg-opacity-25 rounded-lg">
          <div className="text-3xl lg:md:text-4xl text-white mb-4">
            {/* Large Icon */}
            <ion-icon
              className=" flex-shrink-0 h-6 w-6 "
              name="snow"
            ></ion-icon>
          </div>
          <div className="text-sm lg:md:text-2xl text-sky-500 font-semibold mb-2">
            {/* Title */}
            Resources
          </div>
          <div className="text-xs lg:md:text-sm text-white font-semibol ">
            {/* Paragraph */}
            Find your next role with companies that support our mission.
            
          </div>
        </div>

        {/* Column 2 */}
        <div className="text-center p-4 bg-black bg-opacity-25 rounded-lg">
        <div className="text-3xl lg:md:text-4xl text-white mb-4">
            {/* Large Icon */}
            <ion-icon
              className=" flex-shrink-0 h-6 w-6 "
              name="globe"
            ></ion-icon>
          </div>
          <div className="text-base lg:md:text-2xl text-sky-500 font-semibold mb-2">
            {/* Title */}
            Community
          </div>
          <div className="text-xs lg:md:text-sm text-white font-semibol">
            {/* Paragraph */}
            Join our community with Local and Global technical online networks.
          </div>
        </div>

        {/* Column 3 */}
        <div className="text-center p-4 bg-black bg-opacity-25 rounded-lg">
        <div className="text-3xl lg:md:text-4xl text-white mb-4">
            {/* Large Icon */}
            <ion-icon
              className=" flex-shrink-0 h-6 w-6 "
              name="hand"
            ></ion-icon>
          </div>
          <div className="text-base lg:md:text-2xl text-sky-500 font-semibold mb-2">
            {/* Title */}
            Mentorship
          </div>
          <div className="text-xs lg:md:text-sm text-white font-semibol">
            {/* Paragraph */}
            Elevate your leadership skills as a Volunteer, Member, Speaker, or
            Community leader.
          </div>
        </div>

        {/* Column 4 */}
        <div className="text-center p-4 bg-black bg-opacity-25 rounded-lg">
        <div className="text-lg lg:md:text-3xl text-white mb-4">
            {/* Large Icon */}
            <ion-icon
              className=" flex-shrink-0 h-6 w-6 "
              name="laptop"
            ></ion-icon>
          </div>
          <div className="text-base lg:md:text-2xl text-sky-500 font-semibold mb-2">
            {/* Title */}
            Training
          </div>
          <div className="text-xs lg:md:text-sm text-white font-semibol ">
            {/* Paragraph */}
            Level up your technical skills with Online Events, Videos,
            tutorials, and bootcamps.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sleeve;
