import React from "react";
import img1 from "../assets/avi.jpg";

function Team() {
  return (
    <div className="py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl ">
      <div className="mx-auto mb-10 lg:max-w-xl text-center">
        <p className="text-3xl lg:md:text-5xl font-extrabold title-font mb-4 text-sky-500  ">
          Our team
        </p>
      </div>
      <div className="grid gap-10 mx-auto lg:max-w-screen-lg sm:grid-cols-2 lg:grid-cols-4">
        <div className="flex flex-col items-center">
          <img
            className="object-cover w-20 h-20 mb-2 rounded-full shadow"
            src={img1}
            alt="Person"
          />
          <div className="flex flex-col items-center">
            <p className="text-lg font-bold">XXXX</p>
            <p className="text-sm text-sky-500">Product Manager</p>
            <a
              href="/"
              rel="noreferrer"
              target="_blank"
              className="text-sky-600 transition hover:opacity-75"
            >
              <span className="sr-only">Facebook</span>

              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 18"
              >
                <path
                  fillRule="evenodd"
                  d="M7.979 5v1.586a3.5 3.5 0 0 1 3.082-1.574C14.3 5.012 15 7.03 15 9.655V15h-3v-4.738c0-1.13-.229-2.584-1.995-2.584-1.713 0-2.005 1.23-2.005 2.5V15H5.009V5h2.97ZM3 2.487a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                  clipRule="evenodd"
                />
                <path d="M3 5.012H0V15h3V5.012Z" />
              </svg>
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <img
            className="object-cover w-20 h-20 mb-2 rounded-full shadow"
            src={img1}
            alt="Person"
          />
          <div className="flex flex-col items-center">
            <p className="text-lg font-bold">XXXX</p>
            <p className="text-sm text-sky-500">Design Team Lead</p>
            <a
              href="/"
              rel="noreferrer"
              target="_blank"
              className="text-sky-600 transition hover:opacity-75"
            >
              <span className="sr-only">Facebook</span>

              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 18"
              >
                <path
                  fillRule="evenodd"
                  d="M7.979 5v1.586a3.5 3.5 0 0 1 3.082-1.574C14.3 5.012 15 7.03 15 9.655V15h-3v-4.738c0-1.13-.229-2.584-1.995-2.584-1.713 0-2.005 1.23-2.005 2.5V15H5.009V5h2.97ZM3 2.487a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                  clipRule="evenodd"
                />
                <path d="M3 5.012H0V15h3V5.012Z" />
              </svg>
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <img
            className="object-cover w-20 h-20 mb-2 rounded-full shadow"
            src={img1}
            alt="Person"
          />
          <div className="flex flex-col items-center">
            <p className="text-lg font-bold">XXXX</p>
            <p className="text-sm text-sky-500">CTO, Lorem Inc.</p>
            <a
              href="/"
              rel="noreferrer"
              target="_blank"
              className="text-sky-600 transition hover:opacity-75"
            >
              <span className="sr-only">Facebook</span>

              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 18"
              >
                <path
                  fillRule="evenodd"
                  d="M7.979 5v1.586a3.5 3.5 0 0 1 3.082-1.574C14.3 5.012 15 7.03 15 9.655V15h-3v-4.738c0-1.13-.229-2.584-1.995-2.584-1.713 0-2.005 1.23-2.005 2.5V15H5.009V5h2.97ZM3 2.487a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                  clipRule="evenodd"
                />
                <path d="M3 5.012H0V15h3V5.012Z" />
              </svg>
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <img
            className="object-cover w-20 h-20 mb-2 rounded-full shadow"
            src={img1}
            alt="Person"
          />
          <div className="flex flex-col items-center">
            <p className="text-lg font-bold">XXXX</p>
            <p className="text-sm text-sky-500">Human Resources</p>
            <a
              href="/"
              rel="noreferrer"
              target="_blank"
              className="text-sky-600 transition hover:opacity-75"
            >
              <span className="sr-only">Facebook</span>

              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 18"
              >
                <path
                  fillRule="evenodd"
                  d="M7.979 5v1.586a3.5 3.5 0 0 1 3.082-1.574C14.3 5.012 15 7.03 15 9.655V15h-3v-4.738c0-1.13-.229-2.584-1.995-2.584-1.713 0-2.005 1.23-2.005 2.5V15H5.009V5h2.97ZM3 2.487a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                  clipRule="evenodd"
                />
                <path d="M3 5.012H0V15h3V5.012Z" />
              </svg>
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <img
            className="object-cover w-20 h-20 mb-2 rounded-full shadow"
            src={img1}
            alt="Person"
          />
          <div className="flex flex-col items-center">
            <p className="text-lg font-bold">XXXX</p>
            <p className="text-sm text-sky-500">Product Manager</p>
            <a
              href="/"
              rel="noreferrer"
              target="_blank"
              className="text-sky-600 transition hover:opacity-75"
            >
              <span className="sr-only">Facebook</span>

              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 18"
              >
                <path
                  fillRule="evenodd"
                  d="M7.979 5v1.586a3.5 3.5 0 0 1 3.082-1.574C14.3 5.012 15 7.03 15 9.655V15h-3v-4.738c0-1.13-.229-2.584-1.995-2.584-1.713 0-2.005 1.23-2.005 2.5V15H5.009V5h2.97ZM3 2.487a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                  clipRule="evenodd"
                />
                <path d="M3 5.012H0V15h3V5.012Z" />
              </svg>
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <img
            className="object-cover w-20 h-20 mb-2 rounded-full shadow"
            src={img1}
            alt="Person"
          />
          <div className="flex flex-col items-center">
            <p className="text-lg font-bold">XXXX</p>
            <p className="text-sm text-sky-600">Backend Developer</p>
            <a
              href="/"
              rel="noreferrer"
              target="_blank"
              className="text-sky-600 transition hover:opacity-75"
            >
              <span className="sr-only">Facebook</span>

              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 18"
              >
                <path
                  fillRule="evenodd"
                  d="M7.979 5v1.586a3.5 3.5 0 0 1 3.082-1.574C14.3 5.012 15 7.03 15 9.655V15h-3v-4.738c0-1.13-.229-2.584-1.995-2.584-1.713 0-2.005 1.23-2.005 2.5V15H5.009V5h2.97ZM3 2.487a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                  clipRule="evenodd"
                />
                <path d="M3 5.012H0V15h3V5.012Z" />
              </svg>
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <img
            className="object-cover w-20 h-20 mb-2 rounded-full shadow"
            src={img1}
            alt="Person"
          />
          <div className="flex flex-col items-center">
            <p className="text-lg font-bold">XXXX</p>
            <p className="text-sm text-sky-500">Designer UI/UX</p>
            <a
              href="/"
              rel="noreferrer"
              target="_blank"
              className="text-sky-600 transition hover:opacity-75"
            >
              <span className="sr-only">Facebook</span>

              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 18"
              >
                <path
                  fillRule="evenodd"
                  d="M7.979 5v1.586a3.5 3.5 0 0 1 3.082-1.574C14.3 5.012 15 7.03 15 9.655V15h-3v-4.738c0-1.13-.229-2.584-1.995-2.584-1.713 0-2.005 1.23-2.005 2.5V15H5.009V5h2.97ZM3 2.487a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                  clipRule="evenodd"
                />
                <path d="M3 5.012H0V15h3V5.012Z" />
              </svg>
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <img
            className="object-cover w-20 h-20 mb-2 rounded-full shadow"
            src={img1}
            alt="Person"
          />
          <div className="flex flex-col items-center">
            <p className="text-lg font-bold">XXXX</p>
            <p className="text-sm text-sky-500">Frontend Developer</p>
            <a
              href="/"
              rel="noreferrer"
              target="_blank"
              className="text-sky-600 transition hover:opacity-75"
            >
              <span className="sr-only">Facebook</span>

              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 18"
              >
                <path
                  fillRule="evenodd"
                  d="M7.979 5v1.586a3.5 3.5 0 0 1 3.082-1.574C14.3 5.012 15 7.03 15 9.655V15h-3v-4.738c0-1.13-.229-2.584-1.995-2.584-1.713 0-2.005 1.23-2.005 2.5V15H5.009V5h2.97ZM3 2.487a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                  clipRule="evenodd"
                />
                <path d="M3 5.012H0V15h3V5.012Z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
