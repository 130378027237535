import React from "react";
import Logo from "../assets/coastech_logo.png";

const NavBar = () => {
  const [open, setOpen] = React.useState(false);
  const [flyer, setFlyer] = React.useState(false);
  const [flyerTwo, setFlyerTwo] = React.useState(false);

  return (
    <>
      <div className="sticky z-50 inset-0 bg-blue-950 ">
        <div className=" mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-2 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1 ">
              <a href="/">
                <img
                  className="h-8 w-auto sm:h-14 "
                  src={Logo}
                  alt="CTEC KENYA"
                />
              </a>
            </div>

            {/* about section */}

            <div className="mr-4 -my-2 md:hidden">
              <button
                type="button"
                className="p-2 inline-flex items-center text-sky-500 justify-center"
                onClick={() => setOpen(!open)}
              >
                <span className="sr-only">Open menu</span>
                {/* Heroicon name: outline/menu */}
                <ion-icon size="large" name="menu"></ion-icon>
              </button>
            </div>
            <nav className="hidden md:flex space-x-10">
              <a
                href="/"
                className="text-sm font-bold mt-0.5 text-white hover:text-sky-500"
              >
                Home
              </a>
              <div className="relative">
                <button
                  type="button"
                  className="group inline-flex items-center text-sm font-bold text-white hover:text-sky-500"
                  onClick={() => {
                    setFlyerTwo(!flyerTwo);
                    setFlyer(false);
                  }}
                >
                  <span>About</span>

                  <svg
                    className={
                      flyerTwo === true
                        ? "transform rotate-180 ml-2 h-5 w-5 text-white group-hover:text-sky-500 transition ease-out duration-200"
                        : "ml-2 h-5 w-5 text-white group-hover:text-sky-500"
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                <div
                  onMouseLeave={() => setFlyerTwo(false)}
                  className={
                    flyerTwo
                      ? " opacity-100 translate-y-0 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                      : " opacity-0 translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                  }
                >
                  <div className="rounded-lg shadow-lg overflow-hidden">
                    <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                      <a
                        href="/about"
                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-sky-100"
                      >
                        {/* Heroicon name: outline/calendar */}
                        <ion-icon
                          class="flex-shrink-0 h-6 w-6 text-sky-500"
                          size="large"
                          name="infinite"
                        ></ion-icon>
                        <div className="ml-4 hover:bg-sky-100">
                          <p className="text-sm font-bold text-blue-950">
                            About CTEC
                          </p>
                          <p className="mt-1 text-xs text-gray-500">
                            Who we are
                          </p>
                        </div>
                      </a>
                      <a
                        href="/about"
                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-sky-100"
                      >
                        {/* Heroicon name: outline/calendar */}
                        <ion-icon
                          class="flex-shrink-0 h-6 w-6 text-sky-500"
                          size="large"
                          name="easel"
                        ></ion-icon>
                        <div className="ml-4">
                          <p className="text-sm font-bold text-blue-950">
                            Mission & vision
                          </p>
                          <p className="mt-1 text-xs text-gray-500">
                            See the essence that defines our Community
                          </p>
                        </div>
                      </a>

                      <a
                        href="/team"
                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-sky-100"
                      >
                        {/* Heroicon name: outline/calendar */}
                        <ion-icon
                          class="flex-shrink-0 h-6 w-6 text-sky-500"
                          size="large"
                          name="people"
                        ></ion-icon>
                        <div className="ml-4">
                          <p className="text-sm font-bold text-blue-950">
                            Team
                          </p>
                          <p className="mt-1 text-xs text-gray-500">
                            Meet Our Team
                          </p>
                        </div>
                      </a>

                      <a
                        href="/gallery"
                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-sky-100"
                      >
                        {/* Heroicon name: outline/calendar */}
                        <ion-icon
                          class="flex-shrink-0 h-6 w-6 text-sky-500"
                          size="large"
                          name="images"
                        ></ion-icon>
                        <div className="ml-4">
                          <p className="text-sm font-bold text-blue-950">
                            Gallery
                          </p>
                          <p className="mt-1 text-xs text-gray-500">
                            See photos of our past meet-ups and events.
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative">
                <button
                  type="button"
                  className=" group text-white inline-flex items-center text-sm font-bold hover:text-sky-500 pb-8'
                  "
                  onClick={() => {
                    setFlyer(!flyer);
                    setFlyerTwo(false);
                  }}
                >
                  <span>Programs</span>

                  <svg
                    className={
                      flyer === true
                        ? "transform rotate-180 ml-2 h-5 w-5 text-white group-hover:text-sky-500 transition ease-out duration-200"
                        : "transform rotate-0 transition ease-out duration-200 ml-2 h-5 w-5 text-white group-hover:text-sky-500"
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                <div
                  onMouseLeave={() => setFlyer(false)}
                  className={
                    flyer
                      ? " opacity-100 translate-y-0 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                      : " opacity-0 translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                  }
                >
                  <div className="rounded-lg shadow-lg overflow-hidden">
                    <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                      <a
                        href="/programs"
                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-sky-100"
                      >
                        {/* Heroicon name: outline/calendar */}
                        <ion-icon
                          class="flex-shrink-0 h-6 w-6 text-sky-500"
                          size="large"
                          name="calendar"
                        ></ion-icon>
                        <div className="ml-4">
                          <p className="text-sm font-bold text-blue-950">
                            Events and Training
                          </p>
                          <p className="mt-1 text-xs text-gray-500">
                            See engagements we have lined up for you to
                            comprehensively develop your tech skills.
                          </p>
                        </div>
                      </a>
                      <a
                        href="/programs"
                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-sky-100"
                      >
                        {/* Heroicon name: outline/calendar */}
                        <ion-icon
                          class="flex-shrink-0 h-6 w-6 text-sky-500"
                          size="large"
                          name="git-merge"
                        ></ion-icon>
                        <div className="ml-4">
                          <p className="text-sm font-bold text-blue-950">
                            Meet-Ups Workshops & Bootcamps
                          </p>
                          <p className="mt-1 text-xs text-gray-500">
                            See our upcoming Tech gatherings to boost your
                            skills and learn new technologies.
                          </p>
                        </div>
                      </a>
                      <a
                        href="/programs"
                        className="-m-3 p-3 flex items-start rounded-lg text-sky-500 hover:bg-sky-100"
                      >
                        {/* Heroicon name: outline/calendar */}
                        <ion-icon
                          size="large"
                          name="cog"
                        ></ion-icon>
                        <div className="ml-4">
                          <p className="text-sm font-bold text-blue-950">
                            Proffesional Coaching
                          </p>
                          <p className="mt-1 text-xs text-gray-500">
                            Guiding techies towards excellence through expert
                            professional coaching and development.
                          </p>
                        </div>
                      </a>
                      <a
                        href="/programs"
                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-sky-100"
                      >
                        {/* Heroicon name: outline/calendar */}
                        <ion-icon
                          class="flex-shrink-0 h-6 w-6 text-sky-500"
                          size="large"
                          name="laptop"
                        ></ion-icon>
                        <div className="ml-4">
                          <p className="text-sm font-bold text-blue-950">
                            Mentorship & Job creation
                          </p>
                          <p className="mt-1 text-xs text-gray-500">
                            Empowering through mentorship, igniting
                            opportunities for job creation and growth.
                          </p>
                        </div>
                      </a>
                    </div>
                    <div className="px-5 py-5 bg-sky-500 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                      <div className="flow-root">
                        <a
                          href="/intro"
                          className="-m-3 p-3 flex items-center rounded-md text-sm font-bold text-blue-950 hover:bg-gray-100"
                        >
                          {/* Heroicon name: outline/play */}
                          <ion-icon
                            className="flex-shrink-0 h-6 w-6 "
                            size="large"
                            name="videocam"
                          ></ion-icon>
                          <span className="ml-3">Watch Demo</span>
                        </a>
                      </div>
                      <div className="flow-root">
                        <a
                          href="/downloads"
                          className="group -m-3 p-3 flex items-center rounded-md text-sm font-bold text-blue-950 hover:bg-gray-100"
                        >
                          {/* Heroicon name: outline/phone */}
                          <ion-icon
                            class="flex-shrink-0 h-6 w-6 "
                            size="large"
                            name="download"
                          ></ion-icon>
                          <span className="ml-3">Downloads</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a
                href="/sponsors"
                className="text-sm mt-0.5 font-bold text-white hover:text-sky-500"
              >
                Sponsors
              </a>
            </nav>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <a
                href="/register"
                className="whitespace-nowrap text-sm font-bold text-white hover:text-sky-500"
              >
                Register today
              </a>
              <a
                href="/partner"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-bold text-white hover:text-sky-500 bg-sky-500 hover:bg-white"
              >
                Partner with Us
              </a>
            </div>
          </div>
        </div>

        {/*Mobile menu, show/hide based on mobile menu state.*/}

        <div
          className={
            open
              ? "opacity-100 scale-100 transition ease-out duration-200 absolute top-0 inset-x-0 p-2 transform origin-top-right md:hidden"
              : "opacity-0 scale-95 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          }
        >
          <div className="rounded-lg shadow-lg  bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <a href="/">
                  <img
                    className="h-8 w-auto transition transform hover:scale-125 "
                    src={Logo}
                    alt="CTEC KENYA"
                  />
                </a>
                <div className="-mr-2">
                  <button
                    type="button"
                    className="bg-white  p-2 inline-flex items-center justify-center text-sky-500  "
                    onClick={() => setOpen(!open)}
                  >
                    {/* Heroicon name: outline/x */}
                    <ion-icon size="large" name="close"></ion-icon>
                  </button>
                </div>
              </div>
              <div className="mt-4">
                <nav className="grid gap-y-5">
                  <a
                    href="/"
                    className="-m-3 p-3 flex items-center rounded-md text-sky-500 hover:bg-sky-100"
                  >
                    <ion-icon size="large" name="snow"></ion-icon>
                    <span className="ml-3 text-sm  font-bold text-gray-900">
                      Home
                    </span>
                  </a>
                  <a
                    href="/about"
                    className="-m-3 p-3 flex items-center rounded-md text-sky-500 hover:bg-sky-100"
                  >
                    <ion-icon size="large" name="infinite"></ion-icon>
                    <span className="ml-3 text-sm font-bold text-gray-900">
                      About CTEC
                    </span>
                  </a>
                  <a
                    href="/programs"
                    className="-m-3 p-3 flex items-center rounded-md text-sky-500 hover:bg-sky-100"
                  >
                    <ion-icon size="large" name="calendar"></ion-icon>

                    <span className="ml-3 text-sm font-bold text-gray-900">
                      Events & Training
                    </span>
                  </a>
                  <a
                    href="/programs"
                    className="-m-3 p-3 flex items-center rounded-md text-sky-500 hover:bg-sky-100"
                  >
                    <ion-icon size="large" name="git-merge"></ion-icon>

                    <span className="ml-3 text-sm font-bold text-gray-900">
                      Meet Ups & Workshops
                    </span>
                  </a>
                  <a
                    href="/programs"
                    className="-m-3 p-3 flex items-center rounded-md text-sky-500 hover:bg-sky-100"
                  >
                    <ion-icon size="large" name="cog"></ion-icon>

                    <span className="ml-3 text-sm font-bold text-gray-900">
                      Coaching & Mentorship
                    </span>
                  </a>
                  <a
                    href="/team"
                    className="-m-3 p-3 flex items-center rounded-md text-sky-500 hover:bg-sky-100"
                  >
                    <ion-icon size="large" name="people"></ion-icon>
                    <span className="ml-3 text-sm font-bold text-gray-900">
                      Team
                    </span>
                  </a>
                  <a
                    href="/sponsors"
                    className="-m-3 p-3 flex items-center text-sky-500 rounded-md hover:bg-sky-100"
                  >
                    <ion-icon size="large" name="git-commit"></ion-icon>
                    <span className="ml-3 text-sm font-bold text-blue-950">
                      Sponsors
                    </span>
                  </a>
                </nav>
              </div>
            </div>
            <div className="py-4 px-5 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <a
                  href="/gallery"
                  className="-m-1 px-2  flex items-center text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Gallery <ion-icon size="large" name="images"></ion-icon>
                </a>
                <a
                  href="/downloads"
                  className="-m-1 px-2  flex items-center text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Downloads <ion-icon size="large" name="download"></ion-icon>
                </a>
              </div>
            </div>

            <a
              href="/partner"
              className="w-48 mx-auto flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-bold text-white bg-sky-500 hover:bg-sky-700"
            >
              Partner with us
            </a>
            <p className="mt-2 py-2 text-center text-sm font-bold text-gray-500">
              Join Us{" "}
              <a
                href="/register"
                className="text-sky-500 hover:text-indigo-500"
              >
                Register Here
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
