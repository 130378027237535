import React from "react";
import events from "../assets/6.jpg";
import mentor from "../assets/5.jpg";
import coach from "../assets/laptopwater.jpg";

import pic3 from "../assets/muktar.jpg";

function Programs() {
  const toggleButtons = document.querySelectorAll(".toggle-button");

  // Add click event listeners to toggle buttons
  toggleButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const targetId = button.getAttribute("data-target");
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        // Toggle the hidden class on the target element
        targetElement.classList.toggle("hidden");
      }
    });
  });
  return (
    <div className="bg-blue-950 h-full overflow-hidden relative ">
      <div className=" max-w-6xl px-6 py-8 m-auto">
        <h1 className="text-3xl lg:md:text-5xl font-extrabold title-font mb-8 text-center text-sky-500 ">
          Programs
        </h1>
        <div className=" mb-8 grid lg:md:grid-cols-2 lg:md:gap-6 text-white ">
          <div className="my-auto ">
            <div className="flex items-center ">
              <ion-icon
                class="flex-shrink-0 h-6 w-6 text-sky-500 "
                size="large"
                name="calendar"
              ></ion-icon>
              <h1 className="p-8 text-sky-500 lg:md:text-2xl sm:text-sm justify-center mx-auto font-semibold ">
                Events and Training
              </h1>
            </div>
            <p className="text-sm lg:md:text-base mb-8  ">
              Our events and training sessions are essential for tech
              enthusiasts and professionals. They provide opportunities to
              enhance tech skills, stay updated, and excel in the ever-evolving
              tech industry. Open to all skill levels, these sessions offer
              diverse learning experiences, ensuring everyone can benefit. Join
              us to connect with peers, learn from experts, and embrace the
              chance to grow and thrive in technology.
            </p>
          </div>

          <div className="h-auto bg-cover bg-center lg:md:m-auto sm:my-6">
            <img className=" rounded-lg" src={events} alt="" />
          </div>
        </div>
        <div className=" mt-8 mb-8 grid lg:md:grid-cols-2 lg:md:gap-6 text-white ">
          {/* Hide this div on small screens */}
          <div class=" hidden lg:block h-auto bg-cover bg-center m-auto">
            <img className=" rounded-lg" src={pic3} alt="" />
          </div>
          <div className="my-auto ">
            <div className="flex items-center  ">
              <ion-icon
                class="lg:hidden flex-shrink-0 h-6 w-6 text-sky-500"
                size="large"
                name="git-merge"
              ></ion-icon>
              <h1 className=" text-sky-500 lg:md:text-2xl sm:text-sm justify-center mx-auto ml-6 font-semibold ">
                Meet-Ups, Workshops & Bootcamps
              </h1>

              <ion-icon
                class="hidden lg:block flex-shrink-0 h-6 w-6 text-sky-500"
                size="large"
                name="cog"
              ></ion-icon>
            </div>
            <p className="mt-8 text-sm lg:md:text-base mb-8  ">
              Our Meet-Ups, Workshops, and Bootcamps cater to tech enthusiasts
              and professionals looking to delve deep into specific tech topics.
              These sessions offer hands-on learning and networking with
              experts. They bridge the gap between theory and real-world
              application, making them ideal for focused skill enhancement and
              niche technology exploration. Dive into your passion for tech,
              gain practical knowledge, and build a valuable network by
              participating in these engaging gatherings.
            </p>
            {/* Show this div on smaller screens */}
            <div className="lg:hidden h-auto bg-cover bg-center m-auto">
              <img className="rounded-lg" src={pic3} alt="" />
            </div>
          </div>
        </div>
        <div className=" mt-8 mb-8 grid lg:md:grid-cols-2 lg:md:gap-6 text-white ">
          <div className="my-auto ">
            <div className="flex items-center  ">
              <ion-icon
                class=" flex-shrink-0 h-6 w-6 text-sky-500"
                size="large"
                name="cog"
              ></ion-icon>
              <h1 className=" text-sky-500 lg:md:text-2xl sm:text-sm justify-center mx-auto font-semibold ">
                Professional Coaching
              </h1>
            </div>
            <p className="mt-8 text-sm lg:md:text-base mb-8  ">
              Professional coaching is your compass in the tech industry. Our
              expert coaches provide personalized guidance for career
              advancement and technical skill development. In a competitive tech
              landscape, having a mentor is invaluable. Join our coaching
              program to gain insights, set and achieve career goals, and become
              a standout professional. With our support, you can unlock your
              full potential and confidently navigate the complexities of the
              tech world
            </p>
          </div>

          <div className=" mt-4 h-auto bg-cover bg-center lg:md:m-auto sm:my-6">
            <img className=" rounded-lg" src={coach} alt="" />
          </div>
        </div>
        <div className="mt-8 mb-8 grid lg:md:grid-cols-2 lg:md:gap-6 text-white ">
          {/* Hide this div on small screens */}
          <div className="hidden lg:block h-auto bg-cover bg-center m-auto">
            <img className="rounded-lg" src={mentor} alt="" />
          </div>
          <div className="my-auto">
            <div className="flex items-center">
              <ion-icon
                class="lg:hidden flex-shrink-0 h-6 w-6 text-sky-500"
                size="large"
                name="laptop"
              ></ion-icon>
              <h1 className="text-sky-500 lg:md:text-2xl sm:text-sm justify-center mx-auto font-semibold">
                Mentorship & Job Creation
              </h1>
              <ion-icon
                class="hidden lg:block flex-shrink-0 h-6 w-6 text-sky-500"
                size="large"
                name="laptop"
              ></ion-icon>
            </div>
            <p className="mt-8 text-sm lg:md:text-base mb-8">
              Mentorship and job creation are at the heart of our mission to
              empower individuals in the tech field. Mentorship offers insights,
              networking, and community support for aspiring tech professionals.
              Our job creation initiatives open doors to meaningful tech
              careers. Whether you're seeking guidance or new career
              opportunities, our mentorship and job creation programs are your
              pathway to success. Join us on this transformative journey, and
              together, we'll ignite opportunities for personal and professional
              growth in the tech industry.s.
            </p>
            {/* Show this div on smaller screens */}
            <div className="lg:hidden h-auto bg-cover bg-center m-auto">
              <img className="rounded-lg" src={mentor} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Programs;
