import React from 'react'
import logo1 from '../assets/coastech_logo.png'
import logo2 from '../assets/coastech_logo.png'

const Sponsors = () => {
    const images = [logo1, logo2];
  
    const isFewImages = images.length <= 3; // You can adjust the number as needed
  
    return (
      
     
  
  
  
  <div className=" pb-4">
  <h2 className="text-3xl text-center font-bold md:text-3xl text-white ">
              Our Partners
            </h2>
           
            <div className="flex  justify-center">
                <div className="w-16 h-1 rounded-full bg-white inline-flex"></div>
              </div>
  <div
        className={`flex container ${
          isFewImages ? " justify-center items-center" : "h-50"
        } overflow-x-auto pb-4`}
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Sponsor ${index + 1}`}
            className="h-40 w-auto mx-1 object-cover"
          />
        ))}
      </div>
  
  </div>
  
  )
}

export default Sponsors