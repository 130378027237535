import React from "react";

import Bghome from "../assets/home1.jpg";
import Sponsors from "./Sponsors";
import Sleeve from "./Sleeve";
import Faqs from "./Faqs";

function Homehero() {
  return (
    <div>
      <div className=" lg:md:h-screen h-fit overflow-hidden relative">
        <img
          className="absolute inset-0 h-full h w-full object-cover"
          src={Bghome}
          alt=""
        />
        <div className="absolute inset-0 bg-opacity-70 bg-black"></div>

        <div className="flex m-auto items-center justify-center relative">
          <div className="py-16 px-4 mx-auto  text-center lg:py-16 lg:px-12">
            <a
              href="/"
              className="lg:md:inline-flex hidden justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-blue-950 bg-gray-100 rounded-full   hover:bg-blue-950 "
              role="alert"
            >
              <span className="text-xs text-white bg-blue-950 rounded-full text-sky-blue px-4 py-1.5 mr-3">
                New
              </span>{" "}
              <span className="text-sm font-medium">
                Checkout upcoming Events
              </span>
              <svg
                className="ml-2 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
            <h1 className=" lg:md:mt-30 mb-4 text-4xl text-sky-100 font-extrabold tracking-tight leading-none md:text-4xl lg:text-5xl ">
              Let's innovate together!
            </h1>

            <p className=" text-sm  font-bold text-sky-100 lg:text-2xl sm:px-16 xl:px-48">
              CTEC: Mombasa's Tech Hub!{" "}
            </p>
            <div className="flex flex-col mb-2 lg:mb-8 sm:flex-row sm:justify-center ">
              <a
                href="/about"
                className="inline-flex justify-center items-center py-2 lg:md:py-3 px-5 m-14 text-sm lg:md:text-base text-white font-medium text-center text-sky-blue rounded-lg border  border-white"
              >
                Learn more
                <svg
                  className="ml-2 -mr-1 w-4 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
            <Sleeve />
          </div>
        </div>
      </div>

      <Faqs />
      <Sponsors />
    </div>
  );
}

export default Homehero;
