import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Homepage from "./pages/Homepage";
import About from "./pages/About";
import Events from "./pages/Events";
import Programs from "./components/Programs";
import Sponsors from "./components/Sponsors";
import Team from "./components/Team";
import Faqs from "./components/Faqs";
import Error from "./pages/Error";
import Newsletter from "./components/Newsletter";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <BrowserRouter>
       
        <Navbar />

        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="about" element={<About />} />
          <Route path="events" element={<Events />} />
          <Route path="team" element={<Team />} />
          <Route path="sponsors" element={<Sponsors />} />
          <Route path="faq" element={<Faqs />} />
          <Route path="programs" element={<Programs />} />
          <Route path="*" element={<Error />} />
        </Routes>
        <Newsletter />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
