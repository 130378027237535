import React from "react";

const Newsletter = () => {
  return (
    <div className="w-full bg-sky-500 py-16 text-white px-4">
      <div className="max-w-7xl mx-auto grid lg:grid-cols-3">
        <div className="lg:col-span-2 my-4">
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            Want tips & tricks to boost your Tech skills?
          </h1>
          <p>Sign up to our newsletter and stay up to date.</p>
        </div>
        <div className="my-4">
          <div className="flex flex-col sm:flex-row items-center justify-between w-full">
            <input
              className="py-3 px-10 flex w-full rounded-md text-black"
              type="email"
              placeholder="Enter Email"
            />
            <button className="bg-blue-950 text-white rounded-md lg:mt-0 mt-6 sm:text-sm font-medium  ml-4  px-10 py-3">
              Subscribe
            </button>
          </div>
          <p>
            We care bout the protection of your data. Read our{" "}
            <span className="text-gray-500">Privacy Policy.</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
