import React from "react";
import Aboutbg from "../assets/hero7.jpg";

function Herosplit() {
  return (
    <div className=" h-auto mx-auto ">
      <div className="w-full h-44 lg:md:h-64 overflow-hidden relative">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={Aboutbg}
          alt=""
        />
        <div className="absolute inset-0 bg-opacity-70 bg-black"></div>
        <div className="flex h-full items-center justify-center relative">
          <h1 className="text-sky-500 text-3xl tracking-tight ml-14 leading-10 font-extrabold sm:text-3xl justify-center items-center sm:leading-none md:text-6xl">
            {" "}
            CoastTech Empowerment Community
          </h1>
        </div>
      </div>

      <div class="w-full ">
        <div class="bg-white h-auto flex justify-center items-center flex-col">
          <section class="text-gray-600 body-font">
            <div class="container py-12 mx-auto">
              <div class="text-center mb-20">
                <h1 class="text-3xl  lg:md:text-5xl font-extrabold title-font text-sky-500 mb-4">
                  About Us
                </h1>
                <p className="test-xs lg:md:text-lg font-semibold mx-4 leading-relaxed xl:w-2/4 lg:w-3/4 lg:md:mx-auto text-gray-500s">
                  We are a community based organization in Mombasa that Focuses
                  on Discovering and Developing upcoming innovative technology
                  ideas through networking, technical training, bootcamps,
                  support, professional mentoring and coaching.
                </p>
                <div className="flex mt-6 justify-center">
                  <div className="w-16 h-1 rounded-full bg-sky-500 inline-flex"></div>
                </div>
              </div>
              <div className="flex flex-wrap sm:-m-4 mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
                <div className="p-4 md:w-1/2 flex flex-col text-center items-center">
                  <div className="w-14 h-14 inline-flex items-center justify-center rounded-full bg-blue-950 text-sky-500 mb-5 flex-shrink-0">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-10 h-10"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                    </svg>
                  </div>
                  <div className="flex-grow">
                    <h2 className="sm:text-3xl lg:md:text-4xl font-extrabold uppercase title-font text-sky-500 mb-3">
                      mission
                    </h2>
                    <p className="leading-relaxed font-semibold text-base">
                      To inspire the community to discover, develop and
                      deliver(DDD) innovative technology ideas to products.
                    </p>
                  </div>
                </div>
                <div className="p-4 md:w-1/2 flex flex-col text-center items-center">
                  <div className="w-14 h-14 inline-flex items-center justify-center rounded-full bg-blue-950 text-sky-500 mb-5 flex-shrink-0">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      className="w-10 h-10"
                      viewBox="0 0 24 24"
                    >
                      <circle cx="6" cy="6" r="3"></circle>
                      <circle cx="6" cy="18" r="3"></circle>
                      <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                    </svg>
                  </div>
                  <div className="flex-grow">
                    <h2 className="sm:text-3xl lg:md:text-4xl font-extrabold uppercase title-font text-sky-500 mb-3">
                      Vision
                    </h2>
                    <p className="leading-relaxed font-semibold text-base">
                      To create a tech ecosystem that will impact the entire
                      Community at large
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Herosplit;
