import React from "react";
import Abouthero from '../components/Abouthero';
import Programs from "../components/Programs";
import Team from "../components/Team";


function About() {
  return (
    <>
      <Abouthero />
      <Programs />
      <Team />
    </>
  );
}

export default About;
