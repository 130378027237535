import React from "react";

function Error() {
  return (
    <div className="flex mx-auto items-center justify-center p-20 bg-white ">
      <div className="flex flex-col items-center">
        <div className="text-sky-500 font-bold text-9xl">404</div>

        <div className="font-bold text-3xl mt-6 xl:text-7xl lg:text-6xl md:text-5xl">
          This page does not exist
        </div>

        <div className="text-gray-400 font-medium text-sm md:text-xl lg:text-lg mt-8">
          The page you are looking for could not be found.
        </div>
        <button className="bg-blue-950 text-white rounded-md  mt-6 sm:text-sm font-medium  px-10 py-3">
          Return to Homepage
        </button>
      </div>
    </div>
  );
}

export default Error;
