import React from "react";

function Faqs() {
  return (
    <div>
      <section className="bg-blue-950 text-white min:h-screen ">
        <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
          <h2 className="text-white mb-12 text-2xl font-bold leading text-center sm:text-3xl">
            Frequently Asked Questions
          </h2>
          <div className="flex flex-col divide-y sm:px-8 lg:px-12 xl:px-32 divide-gray-700">
            <details>
              <summary className="py-2 outline-none cursor-pointer focus:underline">
                How can I join?
              </summary>
              <div className="text-[#0da4d4] px-4 pb-4">
                <p>
                  You can follow or reach out to the community on by clicking on
                  these links{"; "}
                  <a
                    href="/"
                    target="_blank"
                    rel="noreferrer"
                    className ="text-gray-400 underline hover:text-white"
                  >
                    LinkedIn
                  </a>
                  {", "}{" "}
                  <a
                    href="/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-400 underline hover:text-white"
                  >
                    Discord
                  </a>{" "}
                  or{" "}
                  <a
                    href="/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-400 underline hover:text-white"
                  >
                    Twitter
                  </a>{" "}
                  and you will get guidance and necessary support from a member
                  of our team.
                </p>
              </div>
            </details>

            <details>
              <summary className="py-2 outline-none cursor-pointer focus:underline">
                Are there any membership fees?
              </summary>
              <div className=" text-[#0da4d4] px-4 pb-4">
                <p>
                  It's Free. Coast Tech Empowerment Community does not charge
                  any membership fee.
                </p>
              </div>
            </details>
            <details>
              <summary className="py-2 outline-none cursor-pointer focus:underline">
                How can I attend Coast Tech Empowerment Events?
              </summary>
              <div className=" text-[#0da4d4] px-4 pb-4 space-y-2">
                <p>
                  All our events are open to all. Click{" "}
                  <a
                    className="text-gray-400 underline hover:text-white"
                    href="/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>{" "}
                  to check out upcoming events.
                </p>
              </div>
            </details>
            <details>
              <summary className="py-2 outline-none cursor-pointer focus:underline">
                Is the community only for proffessionals?
              </summary>
              <div className=" text-[#0da4d4] px-4 pb-4 space-y-2">
                <p>
                  Our community comprises of both students and proffessionals
                  who are involved in all modern technology fields. Software
                  Developers, Data Scientists, IOT, Systems security
                  specialists, Product Managers, UI/UX Designers, Devops
                  Professionals, Technical Writers and many others.
                </p>
              </div>
            </details>
            <details>
              <summary className="py-2 outline-none cursor-pointer ">
                How can my organisation partner with Coast Tech Empowerment?
              </summary>
              <div className=" text-[#0da4d4] px-4 pb-4 space-y-2">
                <p>
                  Yes. The Coast Tech Empowerment Community is happy to partner
                  with your organisation and any other like-minded open-source
                  community, tech startup or corporate looking to bring positive
                  change to Technology in Kenya and beyond.
                </p>
              </div>
            </details>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Faqs;
