import React from "react";
import Homehero from "../components/Homehero";


function Homepage() {
  return (
    <div>
      <Homehero />

    </div>
  );
}

export default Homepage;
